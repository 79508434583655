






































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import MButton from '@/shared/components/MButton.vue';
import InfiniteLoading from 'vue-infinite-loading';
import { studentService } from '@/services';
import ToastHelper from '@/shared/helpers/toast';
import { hsLoading } from '@/components';
import { Member, Tag } from '@/types/members';
import _ from 'lodash';
import debounce from 'lodash.debounce';
import TrackingHelper from '@/shared/helpers/tracking';
import { LocationMemberSegment } from '@/libs/member';

const AuthModule = namespace('auth');
const MemberModule = namespace('member');
const SchoolModule = namespace('school');

@Component({
  components: {
    hsLoading,
    MButton,
    InfiniteLoading,
  },
})
export default class AddTagModal extends Vue {
  @Prop({ required: true }) modalId: string;
  @Prop({ required: true }) selectedMembers: Member[];
  @Prop({ default: true }) showBtnCreateAndEditTags: boolean;

  @AuthModule.State loggedUser!: any;
  @SchoolModule.State selectedSchool!: any;

  @MemberModule.Action updateMember!: (payload: any) => any;
  @MemberModule.Action selectMember!: (member: Member) => void;

  tagList: Tag[] = [];
  selectedTags: number[] = [];
  search: string = '';

  isLoading: boolean = true;
  isLoadingBtn: boolean = false;
  isLoadingSearch: boolean = false;

  page: number = 1;
  perPage: number = 10;
  totalTags: number = 0;

  prefixLang: string = 'sparkmembers.members-list.tags-modal';

  created() {
    this.searchTags = debounce(this.searchTags, 500);
  }

  async mounted() {
    await this.getTags();
    this.initMemberTags();
    this.closeModal();
  }

  @Watch('search')
  async onChangeSearch() {
    this.isLoadingSearch = true;
    this.tagList = [];
    this.page = 1;
    await this.searchTags(this.search);
  }

  initMemberTags() {
    if (this.selectedMembers.length == 1) {
      this.selectedTags = this.selectedMembers.flatMap(member => member.categories.map(cat => cat.id));
    }
  }

  closeModal() {
    this.$root.$on('bv::modal::hide', (_: any, modalId: string) => {
      modalId === 'add-tag-modal' && this.$emit('closeModal');
    });
  }

  async getTags(getByName: string = '') {
    try {
      const query = [
        { key: 'page', value: this.page },
        { key: 'per_page', value: this.perPage },
        { key: 'sort', value: 'id' },
        { key: 'direction', value: 'desc' },
      ];

      getByName !== '' && query.push({ key: 'name', value: `~${getByName}` });

      const { categories, total_count } = await studentService.getUserCategories(query);

      this.checkDuplicatedTags(categories);

      this.totalTags = total_count;
    } catch (error) {
      ToastHelper.dangerMessage(this.$t(`${this.prefixLang}.toast.get-tags-error`));
    } finally {
      this.isLoading = false;
      this.isLoadingSearch = false;
    }
  }

  searchTags(search: string) {
    return this.getTags(search);
  }

  checkDuplicatedTags(categories: Tag[]) {
    this.tagList = _.unionBy(this.tagList, categories, 'id');
  }

  async infiniteScrollHandler($state: any) {
    try {
      if (this.tagList.length < this.totalTags) {
        this.page += 1;
        await this.getTags(this.search);
        $state.loaded();
      } else {
        $state.complete();
      }
    } catch (error) {
      ToastHelper.dangerMessage(this.$t(`${this.prefixLang}.toast.get-tags-error`));
    }
  }

  async addTag() {
    try {
      this.isLoadingBtn = true;
      const selectedMembersIds = this.selectedMembers.map(member => member.id);

      if (this.selectedMembers.length == 1) {
        const payload = {
          category_ids: this.selectedTags,
          ...this.selectedMembers[0],
        };
        await this.updateMember(payload);
        const { data } = await studentService.getStudent(selectedMembersIds);
        this.selectMember(data);
      } else {
        await studentService.updateUsersCategories(this.selectedTags, selectedMembersIds);
        this.$emit('loadMembers');
      }

      this.$emit('closeModal');

      ToastHelper.successMessage(this.$t(`${this.prefixLang}.toast.add-tag-success`));

      this.trackingMemberTagAdded(this.selectedMembers.length, this.selectedTags.length);
    } catch (error) {
      ToastHelper.dangerMessage(this.$t(`${this.prefixLang}.toast.add-tag-error`));
    } finally {
      this.isLoadingBtn = false;
    }
  }

  trackingMemberTagAdded(member_count: number, tag_count: number) {
    const location = this.$route.params.id
      ? LocationMemberSegment.MEMBERS_DETAILS_PAGE
      : LocationMemberSegment.MEMBERS_LIST_PAGE;
    TrackingHelper.trackMemberTagAdded(
      location,
      this.loggedUser.email,
      this.selectedSchool.id,
      member_count,
      tag_count
    );
  }
}
